import React from 'react';
import { Result } from 'antd';
import IntegrationParams from '../types/IntegrationParams';
import graylogService from '../services/graylogServices';

interface IIntegrationParams {
    integrationParams: IntegrationParams;
}

function IntegrationParamsComponent(WrappedComponent: any) {
    return class extends React.Component<IIntegrationParams> {
        render() {
            const { token, unti_id, activity_uuid, darkMode } = this.props.integrationParams;

            if (!token || !unti_id || !activity_uuid) {
                (async () => {
                    await graylogService.sendMessage({
                        short_message: 'Empty integration params',
                        full_message: 'Empty integration params',
                    });
                })();
            }

            return (
                <>
                    {token && unti_id && activity_uuid ? (
                        <WrappedComponent
                            {...this.props}
                            token={token}
                            unti_id={unti_id}
                            activity_uuid={activity_uuid}
                            darkMode={darkMode}
                        >
                            {this.props.children}
                        </WrappedComponent>
                    ) : (
                        <Result
                            status="warning"
                            title="Извините, произошла ошибка подключения диагностической активности. Пожалуйста, обратитесь в поддержку для исправления ситуации."
                        />
                    )}
                </>
            );
        }
    };
}

export default IntegrationParamsComponent;
