import statisticsService from './statisticsService';
import Rule from '../types/Rule';
import Tile from '../types/Tile';

interface GameOptions {
    rounds: number;
    roundTime: number;
    tilesCount: number;
}

class GameService {
    private options: {
        roundTime: number;
        rounds: number;
        tilesCount: number;
    };
    private colors: string[];
    private shapes: string[][];
    private currentRound: number;
    private score: number = 0;

    rule: Rule;
    board: Tile[] = [];
    round?: {
        rule: Rule;
        board: Tile[];
        id: number;
    };
    isActive: boolean;

    constructor() {
        this.options = {
            rounds: 30,
            roundTime: 4,
            tilesCount: 25,
        };
        this.colors = ['#FFC2A9', '#81BFE1', '#70D199'];
        this.shapes = [
            ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
            [
                'a',
                'b',
                'c',
                'd',
                'e',
                'f',
                'g',
                'h',
                'i',
                'j',
                'k',
                'l',
                'm',
                'n',
                'o',
                'p',
                'q',
                'r',
                's',
                't',
                'u',
                'v',
                'w',
                'x',
                'y',
                'z',
            ],
            [
                '但',
                '是',
                '你',
                '谁',
                '来',
                '到',
                '里',
                '这',
                '母',
                '操',
                '自',
                '己',
                '我',
                '在',
                '的',
                '会',
                '亲',
                '如',
                '想',
                '果',
            ],
        ];
        this.isActive = true;
        this.currentRound = 0;

        this.rule = { shape: this.shapes[0][0], color: this.colors[0], shapeType: '' };
    }

    newGame(options: GameOptions): void {
        this.options = {
            ...this.options,
            ...options,
        };

        statisticsService.startGame();
    }

    endGame(): void {
        statisticsService.endGame();
    }

    getOptions(): GameOptions {
        return this.options;
    }

    generateRandomRule(): void {
        let rule = {
            color: Math.floor(Math.random() * 3),
            shape: Math.floor(Math.random() * 3),
        };

        this.rule = {
            shape: this.shapes[rule.shape][
                Math.floor(Math.random() * this.shapes[rule.shape].length)
            ],
            color: this.colors[rule.color],
            shapeType: '',
        };

        let shapeType;
        if (this.rule.shape.match(/[0-9]/i)) {
            shapeType = 'number';
        } else if (this.rule.shape.match(/[a-z]/i)) {
            shapeType = 'letter';
        } else {
            shapeType = 'hieroglyph';
        }

        this.rule.shapeType = shapeType;
    }

    generateRandomBoard(): void {
        let board = [];
        for (let i = 0; i < this.options?.tilesCount; i++) {
            let itemRule = {
                color: Math.floor(Math.random() * 3),
                shape: Math.floor(Math.random() * 3),
            };

            let tile = {
                id: 100 * this.currentRound + i,
                color: this.colors[itemRule.color],
                shape: this.shapes[itemRule.shape][
                    Math.floor(Math.random() * this.shapes[itemRule.shape].length)
                ],
                isSelected: false,
                shapeType: '',
            };

            let shapeType;
            if (tile.shape.match(/[0-9]/i)) {
                shapeType = 'number';
            } else if (tile.shape.match(/[a-z]/i)) {
                shapeType = 'letter';
            } else {
                shapeType = 'hieroglyph';
            }

            tile.shapeType = shapeType;

            board.push(tile);
        }

        let randomIndex = Math.ceil(Math.random() * (this.options.tilesCount - 1));
        let randomTile = board[randomIndex];
        randomTile.color = this.rule.color;
        randomTile.shape = this.rule.shape;
        randomTile.shapeType = this.rule.shapeType;

        this.board = board;
    }

    getIsActive(): boolean {
        if (this.currentRound >= this.options.rounds) {
            this.isActive = false;
        }

        return this.isActive;
    }

    newRound() {
        this.currentRound++;

        this.generateRandomRule();
        this.generateRandomBoard();

        this.round = {
            id: this.currentRound,
            rule: this.rule,
            board: this.board,
        };

        statisticsService.startRound({
            id: this.currentRound,
            rule: this.rule,
            board: this.board,
        });
    }

    getCurrentRound():
        | {
              rule: Rule;
              board: Tile[];
              id: number;
          }
        | undefined {
        return this.round;
    }

    answer(tile: Tile): void {
        let points = 0;

        if (tile.color === this.rule.color && tile.shapeType === this.rule.shapeType) {
            points = 3;
        } else if (tile.color === this.rule.color || tile.shapeType === this.rule.shapeType) {
            points = 1;
        }

        this.score += points;
        let currentTile = this.round?.board.find(n => n.id === tile.id);

        if (!currentTile) {
            console.error('No tile found');
            return;
        }

        currentTile.isSelected = true;

        statisticsService.saveAnswer({
            id: tile.id,
            color: tile.color,
            shape: tile.shape,
            points: points,
        });
    }

    getScore(): number {
        return this.score;
    }
}

const gameService = new GameService();
export default gameService;
