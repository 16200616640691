import ReactDOM from 'react-dom';
import { createStore, Store } from 'redux';
import { Provider } from 'react-redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { ConfigProvider } from 'antd';
import ru_Ru from 'antd/lib/locale-provider/ru_RU';

import reducers from './store/reducers';
import dispatherService from './services/dispatherService';
import storeService from './services/storeService';
import App from './App';

import pjson from '../package.json';
import './css/index.scss';

const store: Store = createStore(reducers, {}, composeWithDevTools());
storeService.setStore(store);

const root = document.getElementById('webbot');

dispatherService.setParams(root);

ReactDOM.render(
    <Provider store={store}>
        <ConfigProvider locale={ru_Ru}>
            <App />
        </ConfigProvider>
    </Provider>,
    root
);

console.log(`%c Tap Tap version: ${pjson.version} `, 'background-color: #ffdd2d; color: #333;');
