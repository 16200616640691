import React, { Component } from 'react';
import gameService from '../../services/gameService';
import Tile from '../../types/Tile';

interface IGameTileProps {
    tile: Tile;
    updateScore: any;
}
interface IGameTileState {
    isSelected: boolean;
}

class GameTile extends Component<IGameTileProps, IGameTileState> {
    state = {
        isSelected: false,
    };

    handleClick = () => {
        gameService.answer(this.props.tile);
        this.setState({
            isSelected: true,
        });

        this.props.updateScore();
    };

    render() {
        const { color, shape, isSelected } = this.props.tile;
        const style = {
            backgroundColor: color,
        };

        return (
            <div
                className="game-board__tile"
                onClick={e => this.handleClick()}
                style={style}
                aria-disabled={isSelected}
            >
                {shape}
            </div>
        );
    }
}

export default GameTile;
