import Shape from '../types/Shape';
import Rule from '../types/Rule';
import Tile from '../types/Tile';
import gameService from './gameService';
import PreparedStatistics from '../types/PreparedStatistics';
import Statistics from '../types/Statistics';
import LRSResult from '../types/LRSResult';

interface IStatisticsServiceRound {
    rule: Rule;
    board: Tile[];
    id: number;
}

class StatisticsService {
    private statistics: Statistics;

    constructor() {
        this.statistics = {
            levels: [],
            startDate: {},
            endDate: {},
            totalNumberScore1: 0,
            totalNumberScore3: 0,
            totalClicks: 0,
            totalNumbersClicks: 0,
            totalRightClicks: 0,
            totalLettersClicks: 0,
            totalHieroglyphsClicks: 0,
            totalYellowClicks: 0,
            totalBlueClicks: 0,
            totalGreenClicks: 0,
            score: 0,
            clicks: [],
            cognitiveFlex: 0,
            responseSpeed: 0,
        };
    }

    public startGame(): void {
        this.statistics.startDate = new Date();
    }

    public endGame(): void {
        this.statistics.endDate = new Date();
    }

    public startRound(round: IStatisticsServiceRound): void {
        let startDate = this.statistics.startDate;
        let nowDate = new Date();
        let seconds = (nowDate.getTime() - startDate.getTime()) / 1000;

        let ruleShapeType, ruleColorName;

        if (round.rule.color === '#FFC2A9') {
            ruleColorName = 'yellow';
        } else if (round.rule.color === '#81BFE1') {
            ruleColorName = 'blue';
        } else {
            ruleColorName = 'green';
        }

        if (round.rule.shape.match(/[0-9]/i)) {
            ruleShapeType = 'number';
        } else if (round.rule.shape.match(/[a-z]/i)) {
            ruleShapeType = 'letter';
        } else {
            ruleShapeType = 'hieroglyph';
        }

        this.statistics.levels.push({
            rule: {
                // @ts-ignore
                shapeType: ruleShapeType,
                colorName: ruleColorName,
                ...round.rule,
            },
            answers: [],
            numberScore1: 0,
            numberScore3: 0,
            clicks: 0,
            rightClicks: 0,
            numbersClicks: 0,
            lettersClicks: 0,
            hieroglyphsClicks: 0,
            yellowClicks: 0,
            blueClicks: 0,
            greenClicks: 0,
            time: seconds,
        });
    }

    public saveAnswer(answer: { id: number; color: string; shape: Shape; points: number }): void {
        let currentLevel = this.statistics.levels[this.statistics.levels.length - 1];

        let startDate = this.statistics.startDate;
        let nowDate = new Date();
        let seconds = (nowDate.getTime() - startDate.getTime()) / 1000;

        let answerShapeType, answerColorName;

        if (answer.points === 3) {
            currentLevel.numberScore3++;
            currentLevel.rightClicks++;
        } else if (answer.points === 1) {
            currentLevel.numberScore1++;
            currentLevel.rightClicks++;
        }

        if (answer.color === '#FFC2A9') {
            currentLevel.yellowClicks++;
            answerColorName = 'yellow';
        } else if (answer.color === '#81BFE1') {
            currentLevel.blueClicks++;
            answerColorName = 'blue';
        } else {
            currentLevel.greenClicks++;
            answerColorName = 'green';
        }

        currentLevel.clicks++;

        if (answer.shape.match(/[0-9]/i)) {
            currentLevel.numbersClicks++;
            answerShapeType = 'number';
        } else if (answer.shape.match(/[a-z]/i)) {
            currentLevel.lettersClicks++;
            answerShapeType = 'letter';
        } else {
            currentLevel.hieroglyphsClicks++;
            answerShapeType = 'hieroglyph';
        }

        currentLevel.answers.push({
            id: answer.id,
            color: answer.color,
            colorName: answerColorName,
            shapeType: answerShapeType,
            shape: answer.shape,
            points: answer.points,
            time: seconds,
        });

        this.calculateTotalStatistics();
    }

    average(nums: number[]): number {
        return nums.length > 0 ? nums.reduce((a, b) => a + b) / nums.length : 0;
    }

    calculateTotalStatistics(): void {
        let totalStatistics = {
            totalNumberScore1: 0,
            totalNumberScore3: 0,
            totalClicks: 0,
            totalRightClicks: 0,
            totalLettersClicks: 0,
            totalHieroglyphsClicks: 0,
            totalNumbersClicks: 0,
            totalYellowClicks: 0,
            totalBlueClicks: 0,
            totalGreenClicks: 0,
            score: 0,
            clicks: [] as any,
            cognitiveFlex: 0,
            responseSpeed: 0,
        };

        let firstRightClicksTimes: number[] = [];
        let firstClicksTimes: number[] = [];
        let clicksTimes: number[] = [];

        this.statistics.levels.forEach(function (item) {
            totalStatistics.totalNumberScore1 += item.numberScore1;
            totalStatistics.totalNumberScore3 += item.numberScore3;
            totalStatistics.totalClicks += item.clicks;
            totalStatistics.totalRightClicks += item.rightClicks;
            totalStatistics.totalNumbersClicks += item.numbersClicks;
            totalStatistics.totalLettersClicks += item.lettersClicks;
            totalStatistics.totalHieroglyphsClicks += item.hieroglyphsClicks;
            totalStatistics.totalYellowClicks += item.yellowClicks;
            totalStatistics.totalBlueClicks += item.blueClicks;
            totalStatistics.totalGreenClicks += item.greenClicks;

            let levelStartTime = item.time;

            let firstRightClick = item.answers.filter((answer) => answer.points > 0)[0];
            let firstClick = item.answers[0];

            if (firstRightClick) {
                firstRightClicksTimes.push(firstRightClick.time - levelStartTime);
            }

            if (firstClick) {
                firstClicksTimes.push(firstClick.time - levelStartTime);
            }

            item.answers.forEach(function (item) {
                totalStatistics.score += item.points;
                totalStatistics.clicks.push(item.time);
            });

            if (item.answers.length > 0) {
                clicksTimes.push(item.answers[0].time);
            } else {
                clicksTimes.push(gameService.getOptions().roundTime);
            }
        });

        totalStatistics.cognitiveFlex = this.average(firstRightClicksTimes);

        totalStatistics.responseSpeed = this.average(clicksTimes);

        this.statistics = {
            ...this.statistics,
            ...totalStatistics,
        };
    }

    getPreparedStatistics(): PreparedStatistics {
        const statistics = this.statistics;

        let preparedStatistics: PreparedStatistics = {
            Score: statistics.score,
            Score1: statistics.totalNumberScore1,
            Score3: statistics.totalNumberScore3,
            NumClicksAll: statistics.totalClicks,
            NumClicksRightAll: statistics.totalRightClicks,
            NumClicksNumb: statistics.totalNumbersClicks,
            NumClicksHieroglyph: statistics.totalHieroglyphsClicks,
            NumClicksLetters: statistics.totalLettersClicks,
            NumClicksBlue: statistics.totalBlueClicks,
            NumClicksYellow: statistics.totalYellowClicks,
            NumClicksGreen: statistics.totalGreenClicks,
            CognitiveFlex: statistics.cognitiveFlex,
            ResponseSpeed: statistics.responseSpeed,
            Clicks: [],
            NumClicks: [],
            NumClicksRight: [],
        };

        this.statistics.levels.forEach((level, levelIndex) => {
            let levelClicks = level.answers.length;

            level.answers.forEach((answer) => {
                preparedStatistics.Clicks.push(answer.time);
            });

            preparedStatistics.NumClicks.push(levelClicks);
            preparedStatistics.NumClicksRight.push(level.rightClicks);
        });

        return preparedStatistics;
    }

    getStatistics(): LRSResult {
        this.calculateTotalStatistics();

        return {
            _rawData: this.statistics,
            ...this.getPreparedStatistics(),
        };
    }
}

const statisticsService = new StatisticsService();
export default statisticsService;
