import React from 'react';
import Game from './components/Game/Game';
import IntegrationParams from './components/IntegrationParams';
import { connect } from 'react-redux';
import AppState from './types/AppState';
import IntegrationParamsType from './types/IntegrationParams';
import Mode from './types/Mode';
import WarningMessage from './components/WarningMessage/WarningMessage';
import Result from './components/Result/Result';

interface IAppProps {
    mode: Mode;
    integrationParams: IntegrationParamsType;
}

function App(props: IAppProps) {
    let content;
    switch (props.mode) {
        case 'game':
            content = (
                <div className={props.integrationParams.darkMode ? 'app app_dark' : 'app'}>
                    <Game />
                </div>
            );
            break;
        case 'result':
            content = (
                <div className={props.integrationParams.darkMode ? 'app app_dark' : 'app'}>
                    <Result />
                </div>
            );
            break;
        case 'warning':
            content = (
                <div className={props.integrationParams.darkMode ? 'app app_dark' : 'app'}>
                    <WarningMessage />
                </div>
            );
            break;
    }

    return content;
}

const mapStateToProps = (store: AppState) => {
    return {
        integrationParams: store.general.integrationParams,
        mode: store.general.mode,
    };
};

export default connect(mapStateToProps)(IntegrationParams(App));
