import actions from '../actions/types';
import GeneralState from '../../types/GeneralState';
import StoreAction from '../../types/StoreAction';
import { updateObject } from './utility';

const initialState: GeneralState = {
    mode: 'game',
    warningType: 'none',
    integrationParams: {
        token: '',
        unti_id: '',
        activity_uuid: '',
        darkMode: false,
    },
};

const exp = (state: GeneralState = initialState, action: StoreAction) => {
    switch (action.type) {
        case actions.general.SET_INTEGRATION_PARAMS: {
            let currentState = Object.assign({}, state);
            currentState.integrationParams = action.payload;

            return updateObject(state, currentState);
        }
        case actions.general.SET_MODE: {
            let currentState = Object.assign({}, state);
            currentState.mode = action.payload;

            return updateObject(state, currentState);
        }
        case actions.general.SET_WARNING_TYPE: {
            let currentState = Object.assign({}, state);
            currentState.warningType = action.payload;

            return updateObject(state, currentState);
        }
        default:
            return state;
    }
};

export default exp;
