import React, { Component } from 'react';
import GameTile from './GameTile';
import Tile from '../../types/Tile';

interface IGameRoundProps {
    board: Tile[];
    tile?: Tile;
    updateScore: any;
}

class GameRound extends Component<IGameRoundProps> {
    render() {
        const { board } = this.props;

        const listItems = board.map((item, idx) => (
            <GameTile key={idx} tile={item} updateScore={this.props.updateScore} />
        ));

        return (
            <div className="game-board">
                <div className="game-board__tiles">{listItems}</div>
            </div>
        );
    }
}

export default GameRound;
