import LRSResult from '../types/LRSResult';
import statisticsService from './statisticsService';
import graylogService from './graylogServices';
import dispatcherService from './dispatherService';
import storeService from './storeService';

const lrs = 'https://my.2035.university';

const environment = {
    LRSResultFormat: `${lrs}/xapi/v1/results/tap-tap-game`,
    proxyHost: 'https://bypass.2035.university/api/activity',
    t: 'c8d46d341bea4fd5bff866a65ff8aea9',
    production: true,
};

interface IframeParams {
    token: string;
    unti_id: string;
    activity_uuid: string;
}

class IntegrationService {
    public sendStart(params: IframeParams): Promise<Response> {
        return fetch(`${environment.proxyHost}/${params.activity_uuid}/start`, {
            body: this.buildPleBody(params),
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        });
    }

    public sendEnd(params: IframeParams): Promise<Response> {
        return fetch(`${environment.proxyHost}/${params.activity_uuid}/end`, {
            body: this.buildPleBody(params),
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        });
    }

    public sendResult(params: IframeParams, result: LRSResult): Promise<Response> {
        return fetch(`${environment.proxyHost}/${params.activity_uuid}/lrsdata`, {
            body: this.buildLrsBody(params, result),
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        });
    }

    public reportProgress(params: IframeParams, progress: number): Promise<Response> {
        return fetch(`${environment.proxyHost}/${params.activity_uuid}/progress`, {
            body: JSON.stringify({
                ...JSON.parse(this.buildPleBody(params)),
                progress: progress,
            }),
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        });
    }

    public buildLrsBody(params: IframeParams, result: any) {
        const extensions = {
            'https://my.2035.university/xapi/v1/results/tap-tap-game': result,
        };

        return JSON.stringify({
            unti_id: params.unti_id,
            token: params.token,
            t: environment.t,
            extensions,
        });
    }

    public buildPleBody(params: IframeParams) {
        return JSON.stringify({
            unti_id: params.unti_id,
            token: params.token,
            t: environment.t,
        });
    }

    public async finish() {
        const integrationParams = storeService.getStore().getState().general.integrationParams;
        let sendResultRequest = await integrationService.sendResult(
            integrationParams,
            statisticsService.getStatistics()
        );

        if (sendResultRequest.status === 200) {
            await integrationService.sendEnd(integrationParams);
            dispatcherService.setMode('result');
            dispatcherService.setWarningType('none');
        } else {
            await graylogService.sendMessage({
                short_message: `[${sendResultRequest.status}] Request error: ${sendResultRequest.url}`,
                full_message: `[${sendResultRequest.status}] Request error: ${sendResultRequest.url}`,
                _body: integrationService.buildLrsBody(
                    integrationParams,
                    statisticsService.getStatistics()
                ),
                _body_result: await sendResultRequest.json(),
            });

            dispatcherService.setMode('warning');
            dispatcherService.setWarningType('result');
        }
    }
}

const integrationService = new IntegrationService();

export default integrationService;
