import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import AppState from '../../types/AppState';
import { Result } from 'antd';
import Countdown from 'antd/es/statistic/Countdown';
import moment from 'moment';
import integrationService from '../../services/integrationService';
import { LoadingOutlined, SendOutlined } from '@ant-design/icons';

function WarningMessage() {
    const [retryCount, setRetryCount] = useState(0);

    const getCountdown = (count: number) => {
        let seconds = count >= 3 ? 15 * count : count === 1 ? 20 : count === 2 ? 30 : 15;

        return moment()
            .add(seconds, 'second')
            .toISOString();
    };

    const resetCountdown = () => {
        setRetryCount(retryCount + 1);
        setRetryDeadline(getCountdown(retryCount + 1));
    };

    const [trying, setTrying] = useState(false);
    const [retryDeadline, setRetryDeadline] = useState(getCountdown(0));

    const { warningType } = useSelector((state: AppState) => ({
        warningType: state.general.warningType,
    }));

    let message = '';
    switch (warningType) {
        case 'start':
            message =
                'Извините, произошла ошибка подключения диагностической активности. Пожалуйста, обратитесь в поддержку для исправления ситуации.';
            break;
        case 'result':
            message =
                'Извините, ваши данные не удалось сохранить. Сейчас мы попробуем ещё раз. Если данные не сохранятся, вы можете начать сначала или обратиться в поддержку.';
            break;
    }

    return (
        <div className="result-warning">
            <Result status="warning" title={message} />
            <Countdown
                className="retry-countdown"
                title={`Следующая попытка через:`}
                value={retryDeadline}
                format="s секунд"
                prefix={trying ? <SendOutlined /> : <LoadingOutlined />}
                onFinish={async () => {
                    setTrying(true);
                    await integrationService.finish();
                    resetCountdown();
                    setTrying(false);
                }}
            />
        </div>
    );
}

export default WarningMessage;
