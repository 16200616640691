import React from 'react';
import gameService from '../../services/gameService';
import integrationService from '../../services/integrationService';

import GameRound from './GameRound';
import GameTimer from './GameTimer';
import Rule from '../../types/Rule';
import Tile from '../../types/Tile';
import dispatcherService from '../../services/dispatherService';
import graylogService from '../../services/graylogServices';
import storeService from '../../services/storeService';

interface IGameState {
    board?: Tile[];
    round?: {
        rule?: Rule;
        board?: Tile[];
    };
    rule?: Rule;
    isActive: boolean;
    score: number;
    isGameOver: boolean;
}
interface IGameProps {}

class Game extends React.PureComponent<IGameProps, IGameState> {
    public state = {
        isActive: false,
        isGameOver: false,
        board: [],
        rule: {
            shapeType: '',
            shape: '',
            color: '',
        },
        score: 0,
    };
    private intervalID: number | undefined;

    updateScore = () => {
        this.setState({
            score: gameService.getScore(),
        });
    };

    startGame = async () => {
        const integrationParams = storeService.getStore().getState().general.integrationParams;

        let sendStartRequest = await integrationService.sendStart(integrationParams);
        if (sendStartRequest.status !== 200) {
            await graylogService.sendMessage({
                short_message: `[${sendStartRequest.status}] Request error: ${sendStartRequest.url}`,
                full_message: `[${sendStartRequest.status}] Request error: ${sendStartRequest.url}`,
                _body: integrationService.buildPleBody(integrationParams),
                _body_result: await sendStartRequest.json(),
            });

            dispatcherService.setMode('warning');
            dispatcherService.setWarningType('start');
        } else {
            gameService.newGame({
                rounds: 15,
                roundTime: 4,
                tilesCount: 25,
            });

            let options = gameService.getOptions();

            this.generateRound();
            this.intervalID = window.setInterval(this.handleRoundFinish, 1000 * options.roundTime);
        }
    };

    handleRoundFinish = () => {
        if (gameService.getIsActive()) {
            this.generateRound();
        } else {
            this.endGame().then(() => {});
        }
    };

    endGame = async () => {
        gameService.endGame();

        clearInterval(this.intervalID);

        dispatcherService.setMode('result');
        await integrationService.finish();

        window.top?.postMessage('FINISH_ACTIVITY', '*');
    };

    generateRound = () => {
        gameService.newRound();

        const round = gameService.getCurrentRound();
        this.setState({
            isActive: true,
            board: round?.board,
            rule: round?.rule,
        });
    };

    render() {
        const { score } = this.state;

        return (
            <div className="game">
                {!this.state.isActive && !this.state.isGameOver && (
                    <div className="game-start__overlay">
                        <div className="game-start">
                            <div className="game__logo">Тап-Тап</div>
                            <div className="game-start__inner">
                                <p className="game-start__title">Приготовьтесь!</p>
                                <p className="game-start__description">
                                    Эта игра длится всего одну минуту, и за это время вам нужно
                                    понять ее правила и набрать максимум баллов.
                                </p>
                                <div
                                    className="button button_red button_l game-start__button"
                                    onClick={this.startGame}
                                >
                                    Поехали!
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {this.state.isActive && !this.state.isGameOver && (
                    <div className="game-gameplay">
                        <div className="game-gameplay__container">
                            <div className="game-gameplay__col game-gameplay__col_left">
                                <GameRound
                                    board={this.state.board}
                                    updateScore={this.updateScore}
                                />
                            </div>
                            <div className="game-gameplay__col game-gameplay__col_right">
                                <div
                                    className="game-rule"
                                    style={{ background: this.state.rule.color }}
                                >
                                    {this.state.rule.shape}
                                </div>
                                <GameTimer />
                                <div className="game-score">
                                    <div className="game-score__icon" />
                                    <p className="game-score__title">{score}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default Game;
