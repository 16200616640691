import React from 'react';
import gameService from '../../services/gameService';

function Result() {
    const score = gameService.getScore();

    return (
        <div className="game">
            <div className="game-end__overlay">
                <div className="game-end">
                    <div className="game-end__inner">
                        <p className="game-end__title">Ваш результат</p>
                        <div className="game-score game-end__score">
                            <div className="game-score__icon" />
                            <p className="game-score__title">{score}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Result;
