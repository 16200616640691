import React from 'react';
import gameService from '../../services/gameService';
import integrationService from '../../services/integrationService';
import storeService from '../../services/storeService';

interface IGameTimerProps {}
interface IGameTimerState {
    secondsLeft: number;
    initialSeconds: number;
}

class GameTimer extends React.PureComponent<IGameTimerProps, IGameTimerState> {
    private intervalID: number | undefined;

    constructor(props: IGameTimerProps) {
        super(props);

        let gameOptions = gameService.getOptions();

        const initialSeconds = gameOptions.rounds * gameOptions.roundTime;

        this.state = {
            secondsLeft: initialSeconds,
            initialSeconds: initialSeconds,
        };

        this.startTimer();
    }

    componentWillUpdate(
        nextProps: Readonly<IGameTimerProps>,
        nextState: Readonly<IGameTimerState>,
        nextContext: any
    ) {
        (async () => {
            const integrationParams = storeService.getStore().getState().general.integrationParams;
            const progress = Math.ceil(
                ((this.state.initialSeconds - this.state.secondsLeft) / this.state.initialSeconds) *
                    100
            );

            await integrationService.reportProgress(integrationParams, progress);
        })();
    }

    componentWillUnmount() {
        clearInterval(this.intervalID);
    }

    startTimer = () => {
        this.intervalID = window.setInterval(() => {
            if (this.state.secondsLeft > 0) {
                this.setState({
                    secondsLeft: this.state.secondsLeft - 1,
                });
            }
        }, 1000);
    };

    render() {
        const seconds = this.state.secondsLeft;

        return (
            <div className="game-timer">
                <div className="game-timer__icon" />
                <p className="game-timer__title">{seconds}</p>
            </div>
        );
    }
}

export default GameTimer;
